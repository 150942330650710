<template>
  <div>
    <v-row class="mb-1">
      <v-col cols="3">
        <h3>Reporting</h3>
        <span v-if="superAdmin" class="caption grey--text lighten-5"
          >Last updated: {{ last_stats_update }}</span
        >
      </v-col>
      <!-- {{ dsp_campaigns.data }} -->
      <v-col cols="3">
        <v-select
          v-if="hide"
          :items="items"
          label="Advertiser"
          single-line
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-menu
          v-if="hide"
          v-model="dateMenu"
          :close-on-content-click="false"
          :nudge-left="24"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRange"
              label="Picker without buttons"
              append-icon="mdi-calendar"
              single-line
              outlined
              dense
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            @input="dateMenu = false"
            range
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <!-- <v-select
          :items="items"
          label="Agency"
          single-line
          outlined
          dense
        ></v-select> -->
        <BtnUploadStats
          @setUploadData="sendUploadData($event)"
        ></BtnUploadStats>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <Reporting
      :headers="headers"
      :campaigns="dsp_campaigns.data"
      :pagination="pagination"
      @triggerPageChange="setPageNumber($event)"
      @filterParams="getCampaigns($event)"
      :loading="loading"
    ></Reporting>
  </div>
</template>

<script>
import Reporting from "../../components/Tables/Reporting.vue";
import BtnUploadStats from "../../components/Btns/BtnUploadStats.vue";
import { mapGetters, mapState } from "vuex";

export default {
  data: () => ({
    loading: true,
    dialog: false,
    filtered_campaigns: [],
    selected_agency: null,
    selected_advertiser: null,
    fetched_advertisers: [],
    pagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0,
    },
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "campaign_id",
      },
      {
        text: "Campaign Name",
        align: "start",
        sortable: false,
        value: "campaign",
        width: "280px",
      },
      {
        text: "Status",
        value: "activity_status",
        width: "90px",
      },
      { text: "Advertiser", value: "client_name", width: "140px" },
      { text: "Objective", value: "campaign_objective", width: "120px" },
      { text: "Type", value: "campaign_type", width: "120px" },
      { text: "Bid Strategy", value: "bid_strategy", width: "130px" },
      {
        text: "Flight Pacing",
        value: "pacing",
        width: "180px",
      },
      { text: "Spend", value: "campaign_spend", width: "100px" },
      {
        text: "Impressions",
        value: "impressions",
        width: "150px",
      },
      { text: "Clicks", value: "clicks", width: "100px" },
      { text: "CTR", value: "ctr", width: "80px" },
      { text: "CPC", value: "cpc", width: "80px" },
      { text: "CPL", value: "cpl", width: "80px" },
      { text: "Engagements", value: "total_engagements", width: "80px" },
      { text: "ER", value: "engagement_rate", width: "80px" },
      { text: "Leads", value: "leads", width: "100px" },
      { text: "Views", value: "views", width: "100px" },
      { text: "VTR", value: "vtr", width: "80px" },
    ],
    error: null,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dates: ["2021-01-01", "2022-12-31"],
    modal: false,
    dateMenu: false,
    search: "",
    apexLoading: false,
    items: ["Foo", "Bar", "Fizz", "Buzz"],
    hide: false,
  }),
  components: {
    Reporting,
    BtnUploadStats,
  },
  async mounted() {
    //Get campaigns
    this.getCampaigns();
    this.getLastStatsUpdate();
  },
  computed: {
    ...mapState({
      dsp_campaigns: (state) => state.dsp.dsp_campaignStat,
      dsp_advertisers: (state) => state.dsp.dsp_advertisers,
      agencies: (state) => state.dsp.agencies,
      last_stats_update: (state) =>
        state.dsp.last_stats_update?.last_stats_update,
    }),
    ...mapGetters({
      superAdmin: "superAdmin",
    }),

    dateRange() {
      return this.dates.join(" -- ");
    },
  },
  watch: {
    dsp_campaigns() {
      this.filtered_campaigns = this.dsp_campaigns.data;

      this.pagination.page = this.dsp_campaigns.meta.current_page;
      this.pagination.totalItems = this.dsp_campaigns.meta.last_page;
      this.pagination.rowsPerPage = this.dsp_campaigns.meta.per_page;
    },
  },
  methods: {
    async getCampaigns(params) {
      let body = {
        page: this.pagination.page,
        params: {
          per_page: params ? params.per_page : 10,
        },
      };

      //Campaigns
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/getCampaignStats", body);
        this.$store.commit("makingApiRequest", false);
        this.loading = false;
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    getColor(status) {
      if (status === "Paid") return "green";
      else if (status === "Credited") return "orange";
      else return "red";
    },

    //get last stats update
    async getLastStatsUpdate() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/getLastStatsUpdate");
        this.$store.commit("makingApiRequest", false);
        this.loading = false;
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    //set page mumber
    async setPageNumber(page) {
      this.pagination.page = page;
    },

    //Filter by agency
    setFilterByAgency(selected_agency) {
      this.selected_agency = selected_agency;
      this.fetched_advertisers = this.selected_agency.client;
    },
    setfilterByAdvertiser(selected_advertiser) {
      this.selected_advertiser = selected_advertiser;

      this.filtered_campaigns = this.dsp_campaigns.data
        .filter(
          (campaign) =>
            campaign.status == "runnable" || campaign.status == "offline"
        )
        .filter(
          (campaign) => campaign.client_id === this.selected_advertiser.id
        );
    },

    async sendUploadData(upload_data) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/uploadDSPReport", upload_data);
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "File Uploaded Successfully!",
          group: "success",
        });
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
  },
};
</script>

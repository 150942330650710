<template v-slot:extension>
  <v-row class="d-flex align-center">
    <v-col class="d-flex align-center justify-end">
      <span class="float-right">
        <template>
          <v-btn
            v-if="superAdmin"
            depressed
            color="primary"
            class="mr-2 mt-1"
            @click="chooseFiles()"
          >
            <input
              id="fileUpload"
              type="file"
              accept="text/csv"
              hidden
              v-on:change="onFileChange"
            />
            Upload DSP Stats
          </v-btn>
        </template>
      </span>
    </v-col>
  </v-row>
</template>

<script>
// import ChangeModule from "./../../ChangeModule.vue";
import Papa from "papaparse";

export default {
  data: () => ({
    dsp_csv_data: "",
    dsp_csv_fields: [
      "#Day",
      "Hour",
      "Creative ID",
      "Impressions",
      "Clicks",
      "Spend (bidder currency)",
    ],
    missing_fields: [],
    upload_data: { data: [] },
    csv_headers: [],
  }),
  watch: {
    dsp_csv_data() {
      this.csvTojson(this.dsp_csv_data);
    },
  },
  computed: {
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
  },
  mounted() {},

  methods: {
    chooseFiles() {
      document.getElementById("fileUpload").click();
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.createCSVFile(files[0]);
    },
    createCSVFile(file) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        vm.csv_file = e.target.result;
        this.is_csv_file_uploaded = true;
      };
      reader.readAsText(file);
      reader.addEventListener("load", (e) => {
        const data = e.target.result;
        this.dsp_csv_data = data;
      });
    },
    csvTojson(csv) {
      const { data, meta } = Papa.parse(csv, { header: true });
      if (meta.fields.length === 0) {
        throw new Error("Failed to parse CSV");
      }

      (this.csv_headers = meta.fields),
        this.dsp_csv_fields.forEach((field) => {
          if (!this.csv_headers.includes(field)) {
            this.missing_fields.push(field);
          }
        });
      const filteredData = data.filter((row) => {
        const values = Object.values(row);
        const hasUndefinedOrNullFields = values.some(
          (field) => field === undefined || field === null
        );
        const hasIncorrectNumberOfFields =
          Object.keys(row).length !== this.csv_headers.length;

        if (hasUndefinedOrNullFields || hasIncorrectNumberOfFields) {
          // Remove rows with undefined or null fields, or incorrect number of fields
          const index = data.indexOf(row);
          data.splice(index, 1);
          return false;
        }
        return true;
      });

      this.upload_data.data = filteredData;
      if (this.missing_fields.length === 0 && this.dsp_csv_data != "") {
        this.$emit("setUploadData", this.upload_data);
      } else {
        this.$notify({
          title:
            "Invalid File. Day, Hour, Creative ID, Impressions, Clicks and Spend required.",
          group: "errors",
        });
        this.missing_fields = [];
      }
    },
  },
};
</script>
